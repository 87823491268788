
<template>
  <ion-page>
    <LoggedInNav title="Configuración" :back="true" backUrl="/dash/" />

    <ion-content>
      <div class="centerCenter">
        <div class="configContenedor" style="padding: 0 10px">
          <ion-item mode="md">
            <ion-label v-on:click="gotoDatosPersonales"
              >Datos personales</ion-label
            >
          </ion-item>
          <ion-item mode="md">
            <ion-label v-on:click="gotoSuscripcionPago"
              >Suscripción y pago</ion-label
            >
          </ion-item>
          <ion-item mode="md">
            <ion-label>Sistema de medidas</ion-label>
            <div style="width: 60px; cursor: pointer" class="centerCenter">
              <div
                :class="$store.getters['User/isMetricSystem'] ? 'selected' : 'notSelected'"
                v-on:click="selectedBadge('1')"
              >
                Métrico
              </div>
            </div>
            <div style="width: 60px; cursor: pointer" class="centerCenter">
              <div
                :class="$store.getters['User/isMetricSystem'] ? 'notSelected' : 'selected'"
                v-on:click="selectedBadge('2')"
              >
                Imperial
              </div>
            </div>
          </ion-item>
          <ion-item mode="md">
            <ion-label v-on:click="gotoContacto">Contacto</ion-label>
          </ion-item>
          <ion-item mode="md">
            <ion-label v-on:click="gotoSobreGramos">Sobre Gramos</ion-label>
          </ion-item>
          <ion-item mode="md" v-if="canShare">
            <ion-label v-on:click="compartirGramos">Compartir Gramos</ion-label>
          </ion-item>
          <ion-item mode="md">
            <ion-label v-on:click="openModal">Cerrar sesión</ion-label>
          </ion-item>
        </div>
      </div>
      
    </ion-content>
  </ion-page>
</template>

<script >
import { settingsOutline, notificationsOutline } from "ionicons/icons";
import {IonPage, modalController, IonLabel, IonItem, IonContent, isPlatform} from "@ionic/vue";
import { defineComponent } from "vue";
import LoggedInNav from "../../components/LoggedInNav";
import Modal from "../../components/Modals/ModalCerrarSesion";
import router from "../../router/index";
import AccountService from "../../services/account.service";
import { useStore } from "vuex";
import { SocialSharing } from "@ionic-native/social-sharing";


export default defineComponent({
  name: "Configuracion",
  components: {
    IonPage,
    LoggedInNav,
    IonLabel, 
    IonItem, 
    IonContent
  },
  data() {
    return {
      title: "Configuración",
      canShare: isPlatform('cordova') || typeof(navigator.share)=='function'
    };
  },
  methods: {
    async openModal() {
      const modal = await modalController.create({
        component: Modal,
        mode: "md"
      });
      return modal.present();
    },
    gotoDatosPersonales() {
      router.push("/configuracion/datosPersonales");
    },
    gotoSuscripcionPago() {
      router.push("/configuracion/suscripcionPago");
    },
    gotoContacto() {
      router.push("/configuracion/contacto");
    },
    gotoSobreGramos() {
      router.push("/configuracion/sobreGramos");
    },
    compartirGramos() {
      const asuntoSharing = 'App GRAMoS'
      const textSharing = '¡Hola! Estoy adelgazando con GRAMoS, ¡Échale un vistazo! https://www.gramosapp.com'
      // const urlSharing = 'https://app.gramosapp.com'
      if (isPlatform('cordova')) {
        SocialSharing.share(textSharing, asuntoSharing, '')
      }
      else if (typeof(navigator.share)=='function'){
        navigator.share({
          // files,
          title: asuntoSharing,
          text: textSharing,
          // url: urlSharing
        })
      }
    },
    selectedBadge(badge) {
      const unit = badge === "1" ? 'M' : 'I';
      this.$store.commit("User/setIsMetricSystem", unit === 'M');
      AccountService.changeUnit({ unit }).then((data) => {
        return
      });
    },
  },
  setup() {
      const store = useStore();
    return {
      settingsOutline,
      notificationsOutline,
      router,
    };
  },
});
</script>

<style scoped>
ion-item {
  border-radius: 0;
  border-bottom: 0px;
  --background: rgba(255, 255, 255, 0);
  margin-top: 0px;
}

ion-label {
  font-family: "Avenir" !important;
  color: #4d4d4d !important;
  cursor: pointer;
}

.selected {
  font-family: "Avenir";
  font-size: 11px;
  color: white;
  background-color: #d46827;
  padding: 7.5px 10px;
  border-radius: 5px;
}

.notSelected {
  font-family: "Avenir";
  font-size: 11px;
  color: #ecba9b;
  background-color: #fcebe1;
  padding: 7.5px 10px;
  border-radius: 5px;
}

.configContenedor {
    width: 100%;
  }
  @media (min-width: 750px) {
    .configContenedor {
      width: 750px;
    }
  }
</style>




























